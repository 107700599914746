export {
    default as DashboardPage
}
from './dashboard'

export {
    default as InsertPage
}
    from './insert'

export {
    default as ListPage
}
    from './list'

export {
    default as AccountPage
}
    from './account'
    
export {
    default as AnalizationPage
}
    from './analization'
    
export {
    default as ErrorPage
}
from './error'

