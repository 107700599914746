
import m from 'mithril'
import echarts from 'echarts'
import stream from 'mithril/stream';
import anime from 'animejs/lib/anime.es.js';
import classNames from 'classnames'


class RowLineGauge {
    constructor(vnode) {
        this.photos = ['mo.jpg', 'sin.jpg', 'wu.jpg', 'lin.jpg']
        this.colors = ['#EB6877', '#f39800', '#89c997', '#00b7ee']
    }
    view(vnode) {
        return m(".row.no-gutters", [
            vnode.attrs.datas.map((item) => {
                return [
                    m('.col-12.py-md-3.py-1', [
                        m(".row", [
                            m(".col-md-3.col-4.pr-0.d-flex.justify-content-center.badge.align-items-center",{
                                class: classNames({
                                    'badge_customer': true,
                                }),
                            }, [
                                // m("img.rounded-circle.w-100", {
                                //     src: `./img/candidate/${this.photos[parseInt(item.id) - 1]}`,
                                // }),
                                m(".circle.rounded-circle.img-animate-plus.rotate-change", {
                                    style: {
                                        height: (!window.isMobile) ? "130px" : "100px",
                                        width: (!window.isMobile) ? "130px" : "100px",
                                        maxHeight: (!window.isMobile) ? "130px" : "100px",
                                        maxWidth: (!window.isMobile) ? "130px" : "100px",
                                        "background-image": `url(${window.BASE_URL}/img/candidate/${this.photos[parseInt(item.id) - 1]})`,
                                        "background-repeat": "no-repeat",
                                        "background-position": "center",
                                        "background-size": "cover",
                                        border: `3px solid ${this.colors[parseInt(item.id) - 1]}`,

                                    }
                                }),
                                m(".ribbon.goldfont", {
                                    style: {
                                        display: "block" 
                                    }
                                }, item.id)
                            ]),
                            m(".col-md-9.col-8.pl-4", [
                                m(".row.h-100.pb-2.d-flex.align-items-end", [
                                    m(".col-12", [
                                        m(`.d-inline-block.arial_num.font-weight-bolder`, {
                                            onupdate: (e) => {
                                                anime({
                                                    targets: e.dom,
                                                    innerHTML: [0, item.total],
                                                    easing: 'linear',
                                                    round: 1
                                                });
                                            },
                                            style: {
                                                fontSize: (window.isMobile) ? "2.2rem" : "3.8rem"
                                            }
                                        }, item.total),
                                        m(".d-inline-block.pl-4", [
                                            m("span.d-block.arial_num", {
                                                onupdate: (e) => {
                                                    anime({
                                                        targets: e.dom,
                                                        innerHTML: (vnode.attrs.total() == 0) ? "0%" : [0, ((item.total / vnode.attrs.total()).toFixed(4) * 100).toFixed(1) + " %"],
                                                        easing: 'linear',
                                                        round: 10
                                                    });
                                                },
                                                style: {
                                                    fontSize: (window.isMobile) ? "1.5rem" : "2.3rem"
                                                }
                                            }, (vnode.attrs.total() != 0) ? ((item.total / vnode.attrs.total()).toFixed(4) * 100).toFixed(1) + " %" : "0%"),
                                            // m("span.d-block", {
                                            //     style: {
                                            //         fontSize: "1rem"
                                            //     }
                                            // }, item.number),
                                        ])
                                    ]),
                                    m(".col-12", {

                                        style: {
                                            opacity: "1",
                                            // paddingRight: (vnode.attrs.total() != 0) ? (70 - ((item.total / vnode.attrs.total()).toFixed(4) * 100)).toFixed(1) + "%" : "100%",
                                        }
                                    }, [
                                        m(".px-3.py-1", {
                                            onupdate: (e) => {
                                                anime({
                                                    targets: e.dom,
                                                    width: (vnode.attrs.total() != 0) ? ((item.total / vnode.attrs.total()).toFixed(4) * 100).toFixed(1) + "%" : "3%", // -> from '28px' to '100%',
                                                    easing: 'easeInOutExpo',
                                                    direction: 'normal',
                                                    loop: false

                                                });
                                            },
                                            style: {
                                                background: this.colors[parseInt(item.id) - 1],
                                                minHeight: (window.isMobile) ? "2.2rem" : "2.7rem"
                                            }
                                        }, [
                                        ])
                                    ]),
                                ])
                            ])
                        ])

                    ]),



                ]
            }),
        ])
    }
}
export default RowLineGauge