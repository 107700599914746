
import m from 'mithril'
import echarts from 'echarts'
import stream from 'mithril/stream';

class BaseGauge {
    constructor(vnode) {

        this.option = {
            title: {
                show: 'false',
            },
            tooltip: {
                trigger: 'item',
                formatter: "{b} <br/> 共 {c} 票 ({d}%)"
            },
            grid: {
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                containLabel: true
            },
            series: [
                {
                    label: {
                        show: false
                    },
                    type: 'pie',
                    radius: '90%',
                    center: ['50%', '40%'],
                    selectedMode: 'single',
                    left: "40%",
                    data: [],
                    // label: {
                    //     normal: {
                    //         position: 'inner'
                    //     }
                    // },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    color: ['#0BB61F', '#0B63B6']


                }
            ]
        }
    }
    oncreate(vnode) {
        this.blue = [14, 6, 2, 3, 12]
        this.green = [1, 17, 11, 15, 8, 10]
        const canvas = vnode.dom.querySelector('.widget_canvas')
        const chart = echarts.init(canvas)
        this.option.series[0].data = []
        this.blue_total = 0
        this.green_total = 0
        let cate_simbol = (vnode.attrs.type == "土城") ? "tu_total" : "xi_total"
        vnode.attrs.datas.map((_item) => {
            if (vnode.attrs.type == "全區") {
                if (this.blue.includes(_item.id)) {
                    this.blue_total += _item.total
                }
                if (this.green.includes(_item.id)) {
                    this.green_total += _item.total
                }
            } else {
                if (this.blue.includes(_item.id)) {
                    this.blue_total += _item[cate_simbol]
                }
                if (this.green.includes(_item.id)) {
                    this.green_total += _item[cate_simbol]
                }
            }
        })
        this.option.series[0].data.push({
            value: this.green_total, name: "民進黨", label: { fontSize: 16 }
        })
        this.option.series[0].data.push({
            value: this.blue_total, name: "國民黨", label: { fontSize: 16 }
        })
        chart.setOption(this.option, true);
    }
    onupdate(vnode) {
        this.blue = [14, 6, 2, 3, 12]
        this.green = [1, 17, 11, 15, 8, 10]
        const canvas = vnode.dom.querySelector('.widget_canvas')
        const chart = echarts.init(canvas)
        this.option.series[0].data = []
        this.blue_total = 0
        this.green_total = 0
        let cate_simbol = (vnode.attrs.type == "土城") ? "tu_total" : "xi_total"
        vnode.attrs.datas.map((_item) => {
            if (vnode.attrs.type == "全區") {
                if (this.blue.includes(_item.id)) {
                    this.blue_total += _item.total
                }
                if (this.green.includes(_item.id)) {
                    this.green_total += _item.total
                }
            } else {
                if (this.blue.includes(_item.id)) {
                    this.blue_total += _item[cate_simbol]
                }
                if (this.green.includes(_item.id)) {
                    this.green_total += _item[cate_simbol]
                }
            }
        })
        this.option.series[0].data.push({
            value: this.green_total, name: "民進黨", label: { fontSize: 16 }
        })
        this.option.series[0].data.push({
            value: this.blue_total, name: "國民黨", label: { fontSize: 16 }
        })
        chart.setOption(this.option, true);
    }
    view(vnode) {
        return m('.mx-auto', {
            style: {
                position: 'relative'
            }
        }, [
            m('.widget_canvas', {
                style: {
                    width: '100%',
                    height: '200px'
                },
                onclick: (e) => {
                    e.preventDefault()
                }
            }),
        ])
    }
}
export default BaseGauge