import m from 'mithril'
import { Tube, Total, Account } from 'app/models'
import classNames from 'classnames'
import GaugeComponent from './_gauge'
import BarComponent from './_barLine'
import stream from 'mithril/stream';


class AnalizationPage {
    constructor() {
        this.colors = ['#EB6877', '#f39800', '#89c997', '#00b7ee']
        this.voters = ['孟藹倫', '袁　興', '吳琪銘', '林金結']

        // this.colors = ['#00b7ee', '#89c997', '#f39800', '#eb6877', '#ffd200']
        // this.voters = ["彭一書", "江怡臻", "黃永昌", "王正", "張昭隆", "洪佳君", "蘇泓欽", "曾進益", "歐金獅", "高乃芸", "廖宜琨", "呂家愷", "陳世榮", "林金結", "林銘仁", "簡元泰", "卓冠廷"]
        this._datas = []
        window.category = "土城"
        Tube.fetchCategory().then((response) => {
            this._datas = response
        })

        this._datas_big = []
        Tube.fetchCategoryCity().then((response) => {
            this._datas_big = response
        })

        this.voter_total = []

        this.bar_voters = [
            { name: '全區', '民進黨': 0, '國民黨': 0 },
            { name: '土城區', '民進黨': 0, '國民黨': 0 },
            // { name: '樹林區', '民進黨': 0, '國民黨': 0 },
            { name: '三峽區', '民進黨': 0, '國民黨': 0 },
            // { name: '鶯歌區', '民進黨': 0, '國民黨': 0 }
        ]

        this.blue = stream([4])
        this.green = stream([3])

        Total.fetchList().then((response) => {
            this.voter_total = response
            response.map((_item) => {
                if (this.blue().includes(_item.id)) {
                    this.bar_voters[0].國民黨 += _item.total
                    this.bar_voters[1].國民黨 += _item.tu_total
                    // this.bar_voters[2].國民黨 += _item.su_total
                    this.bar_voters[2].國民黨 += _item.xi_total
                    // this.bar_voters[4].國民黨 += _item.yi_total
                }
                if (this.green().includes(_item.id)) {
                    this.bar_voters[0].民進黨 += _item.total
                    this.bar_voters[1].民進黨 += _item.tu_total
                    // this.bar_voters[2].民進黨 += _item.su_total
                    this.bar_voters[2].民進黨 += _item.xi_total
                    // this.bar_voters[4].民進黨 += _item.yi_total
                }
            })
        })

        this.voter_total_big = []

        Total.fetchBig().then((response) => {
            this.voter_total_big = response
        })

        this.select = []

        this.types = ["全區分析", "里別分析"]
        this.select_type = "全區分析"


    }

    oninit(state) {

    }


    oncreate(vnode) {

    }

    view() {
        return [
            m('.d-flex.flex-column.justify-content-between', {
                style: {

                    backgroundColor: `rgba(0,0,0,0.7)`,
                    // backgroundSize: "100% 100%",
                    // height: (window.isMobile) ? "100%" : "100vh",
                    // maxHeight: (window.isMobile) ? "100%" : "100vh",
                    minHeight: "100vh",
                    width: "100%",
                }
            }, m(".w-100.h-100.d-flex.justify-content-between.flex-column", {
                style: {
                    background: "rgba(0,0,0,0.3)",
                    padding: (window.isMobile) ? "0px" : "10px 0px",
                }
            }, [
                m(".row.w-100.no-gutters", [
                    m(".col-12.py-2.d-flex.justify-content-between.align-items-center", [
                        m(".badge.badge-light", {
                            style: {
                                fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                            }
                        }, "交叉分析"),
                        m(".d-flex", [
                            m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    m.route.set("/")
                                }
                            },
                                m("", "返回總表")
                            ),
                            m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    m.route.set("/list")
                                }
                            },
                                m("", "返回里別")
                            ),
                        ])
                    ]),
                    m(".col-3.d-flex", {
                        style: {
                            overflow: (window.isMobile) ? "scroll" : ""
                        }
                    }, [
                        this.types.map((_item) => {
                            return [
                                m(".btn.p-1.m-1", {
                                    class: classNames({
                                        'btn-warning': this.select_type == _item,
                                        'btn-light': this.select_type != _item,
                                    }),
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.select_type = _item
                                    }
                                }, _item)
                            ]
                        })
                    ]),

                    (this.select_type == "里別分析") ? [
                        m(".col-md-3.col-6.d-flex", {
                            style: {
                                overflow: (window.isMobile) ? "scroll" : ""
                            }
                        }, [
                            ["土城", "三峽"].map((_item) => {
                                return [
                                    m(".btn.p-1.m-1", {
                                        class: classNames({
                                            'btn-warning': window.category == _item,
                                            'btn-light': window.category != _item,
                                        }),
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.select = []
                                            window.category = _item
                                            Tube.fetchCategory().then((response) => {
                                                this._datas = response
                                            })
                                            Tube.fetchCategoryCity().then((response) => {
                                                this._datas_big = response
                                            })
                                        }
                                    }, _item)
                                ]
                            })
                        ]),
                        m(".col-12.d-flex", {
                            style: {
                                overflow: (window.isMobile) ? "scroll" : ""
                            }
                        }, [
                            m(".btn.btn-outline-light.p-1.m-1", {
                                class: classNames({
                                    'active': this.select.length == Object.keys(this._datas).length,
                                }),
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (this.select.length == Object.keys(this._datas).length) {
                                        this.select = []
                                    } else {
                                        this.select = Object.keys(this._datas)
                                    }
                                }
                            }, "全選"),
                            Object.keys(this._datas).map((_item) => {
                                return [
                                    m(".btn.p-1.m-1", {
                                        class: classNames({
                                            'btn-warning': this.select.includes(_item),
                                            'btn-light': !this.select.includes(_item),
                                        }),
                                        onclick: (e) => {
                                            e.preventDefault()
                                            if (this.select.includes(_item)) {
                                                this.select.splice(this.select.indexOf(_item), 1)
                                            } else {
                                                this.select.push(_item)
                                            }
                                        }
                                    }, _item.substr(0, 2))
                                ]
                            })
                        ]),
                    ] : [

                    ],

                    //政黨選擇
                    m(".col-12.d-flex", {
                        style: {
                            overflow: (window.isMobile) ? "scroll" : ""
                        }
                    }, [
                        m(".btn.p-1.m-1.btn-primary", "國民黨"),
                        this.voters.map((_item, index) => {
                            return [
                                m(".btn.p-1.m-1", {
                                    class: classNames({
                                        'btn-primary': this.blue().includes(index + 1),
                                        'btn-light': !this.blue().includes(index + 1),
                                    }),
                                    onclick: (e) => {
                                        e.preventDefault()
                                        if (this.green().includes(index + 1)) {
                                            this.green().splice(this.green().indexOf(index + 1), 1)
                                        }
                                        if (this.blue().includes(index + 1)) {
                                            this.blue().splice(this.blue().indexOf(index + 1), 1)
                                        } else {
                                            this.blue().push(index + 1)
                                        }

                                        this.bar_voters = [
                                            { name: '全區', '民進黨': 0, '國民黨': 0 },
                                            { name: '土城區', '民進黨': 0, '國民黨': 0 },
                                            // { name: '樹林區', '民進黨': 0, '國民黨': 0 },
                                            { name: '三峽區', '民進黨': 0, '國民黨': 0 },
                                            // { name: '鶯歌區', '民進黨': 0, '國民黨': 0 }
                                        ]

                                        this.voter_total.map((_item) => {
                                            if (this.blue().includes(_item.id)) {
                                                this.bar_voters[0].國民黨 += _item.total
                                                this.bar_voters[1].國民黨 += _item.tu_total
                                                // this.bar_voters[2].國民黨 += _item.su_total
                                                this.bar_voters[2].國民黨 += _item.xi_total
                                                // this.bar_voters[4].國民黨 += _item.yi_total
                                            }
                                            if (this.green().includes(_item.id)) {
                                                this.bar_voters[0].民進黨 += _item.total
                                                this.bar_voters[1].民進黨 += _item.tu_total
                                                // this.bar_voters[2].民進黨 += _item.su_total
                                                this.bar_voters[2].民進黨 += _item.xi_total
                                                // this.bar_voters[4].民進黨 += _item.yi_total
                                            }
                                        })
                                    }
                                }, _item)
                            ]
                        })
                    ]),
                    m(".col-md-11.d-flex.col-12", {
                        style: {
                            overflow: (window.isMobile) ? "scroll" : ""
                        }
                    }, [
                        m(".btn.p-1.m-1.btn-success", "民進黨"),
                        this.voters.map((_item, index) => {
                            return [
                                m(".btn.p-1.m-1", {
                                    class: classNames({
                                        'btn-success': this.green().includes(index + 1),
                                        'btn-light': !this.green().includes(index + 1),
                                    }),
                                    onclick: (e) => {
                                        e.preventDefault()
                                        if (this.blue().includes(index + 1)) {
                                            this.blue().splice(this.blue().indexOf(index + 1), 1)
                                        }
                                        if (this.green().includes(index + 1)) {
                                            this.green().splice(this.green().indexOf(index + 1), 1)
                                        } else {
                                            this.green().push(index + 1)
                                        }

                                        this.bar_voters = [
                                            { name: '全區', '民進黨': 0, '國民黨': 0 },
                                            { name: '土城區', '民進黨': 0, '國民黨': 0 },
                                            // { name: '樹林區', '民進黨': 0, '國民黨': 0 },
                                            { name: '三峽區', '民進黨': 0, '國民黨': 0 },
                                            // { name: '鶯歌區', '民進黨': 0, '國民黨': 0 }
                                        ]

                                        this.voter_total.map((_item) => {
                                            if (this.blue().includes(_item.id)) {
                                                this.bar_voters[0].國民黨 += _item.total
                                                this.bar_voters[1].國民黨 += _item.tu_total
                                                // this.bar_voters[2].國民黨 += _item.su_total
                                                this.bar_voters[2].國民黨 += _item.xi_total
                                                // this.bar_voters[4].國民黨 += _item.yi_total
                                            }
                                            if (this.green().includes(_item.id)) {
                                                this.bar_voters[0].民進黨 += _item.total
                                                this.bar_voters[1].民進黨 += _item.tu_total
                                                // this.bar_voters[2].民進黨 += _item.su_total
                                                this.bar_voters[2].民進黨 += _item.xi_total
                                                // this.bar_voters[4].民進黨 += _item.yi_total
                                            }
                                        })
                                    }
                                }, _item)
                            ]
                        })
                    ]),
                    m(".col-md-1.d-flex.justify-content-end.col-12", {
                        style: {
                            overflow: (window.isMobile) ? "scroll" : ""
                        }
                    }, [
                        m(".btn.btn-outline-light.p-1.m-1", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.blue = stream([14, 6, 2, 3, 12])
                                this.green = stream([1, 17, 11, 15, 8, 10])
                                this.bar_voters = [
                                    { name: '全區', '民進黨': 0, '國民黨': 0 },
                                    { name: '土城區', '民進黨': 0, '國民黨': 0 },
                                    // { name: '樹林區', '民進黨': 0, '國民黨': 0 },
                                    { name: '三峽區', '民進黨': 0, '國民黨': 0 },
                                    // { name: '鶯歌區', '民進黨': 0, '國民黨': 0 }
                                ]

                                this.voter_total.map((_item) => {
                                    if (this.blue().includes(_item.id)) {
                                        this.bar_voters[0].國民黨 += _item.total
                                        this.bar_voters[1].國民黨 += _item.tu_total
                                        // this.bar_voters[2].國民黨 += _item.su_total
                                        this.bar_voters[2].國民黨 += _item.xi_total
                                        // this.bar_voters[4].國民黨 += _item.yi_total
                                    }
                                    if (this.green().includes(_item.id)) {
                                        this.bar_voters[0].民進黨 += _item.total
                                        this.bar_voters[1].民進黨 += _item.tu_total
                                        // this.bar_voters[2].民進黨 += _item.su_total
                                        this.bar_voters[2].民進黨 += _item.xi_total
                                        // this.bar_voters[4].民進黨 += _item.yi_total
                                    }
                                })
                            }
                        }, "還原預設政黨")
                    ]),


                    (this.select_type == "里別分析") ? [
                        //市議員
                        m(".col-12.d-flex.text-center.py-", [
                            m("span", { style: { fontSize: "2rem", color: "#FFF" } }, "立法委員")
                        ]),
                        m(".col-12.d-flex", {
                            style: {
                                overflow: (window.isMobile) ? "scroll" : ""
                            }
                        }, [
                            m(".row.w-100.no-gutters", [
                                m(".col-12", [
                                    m("table.table.table-hover.table-bordered.mb-0", [
                                        m("thead", [
                                            m("tr", {
                                                style: {
                                                    backgroundColor: "#F2E7F7",
                                                    fontSize: "1rem"
                                                }
                                            }, [
                                                m("th[scope='col'][width='3%'].p-1.text-center", "里別"),
                                                this.select.map((_item) => {
                                                    return [
                                                        m("th[scope='col'].p-1.text-center", _item.substr(0, 2)),
                                                    ]
                                                }),
                                            ])
                                        ]),
                                        m("tbody", [
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                        backgroundColor: "rgba(82,199,87,0.4)"
                                                    }
                                                }, "民進黨"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count = this._datas[_data].reduce((accumulator, _item, index) => {
                                                        let _temp = 0
                                                        this.green().map((_voter_number) => {
                                                            return _temp += parseInt(_item["voter" + (_voter_number).toString()])
                                                        })
                                                        return accumulator + _temp
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                backgroundColor: "rgba(82,199,87,0.4)",
                                                            }
                                                        }, _count),

                                                    ]
                                                }),
                                            ]),
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                        backgroundColor: "rgba(85,133,213,0.4)"
                                                    }
                                                }, "國民黨"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count = this._datas[_data].reduce((accumulator, _item, index) => {
                                                        let _temp = 0
                                                        this.blue().map((_voter_number) => {
                                                            return _temp += parseInt(_item["voter" + (_voter_number).toString()])
                                                        })
                                                        return accumulator + _temp
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                backgroundColor: "rgba(85,133,213,0.4)",
                                                            }
                                                        }, _count),

                                                    ]
                                                }),
                                            ]),
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                    }
                                                }, "差"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count_green = this._datas[_data].reduce((accumulator, _item, index) => {
                                                        let _temp = 0
                                                        this.green().map((_voter_number) => {
                                                            return _temp += parseInt(_item["voter" + (_voter_number).toString()])
                                                        })
                                                        return accumulator + _temp
                                                    }, 0)
                                                    const _count_blue = this._datas[_data].reduce((accumulator, _item, index) => {
                                                        let _temp = 0
                                                        this.blue().map((_voter_number) => {
                                                            return _temp += parseInt(_item["voter" + (_voter_number).toString()])
                                                        })
                                                        return accumulator + _temp
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                color: ((_count_green - _count_blue) > 0) ? "#00FF49" : "#FF0000"
                                                            }
                                                        }, _count_green - _count_blue),

                                                    ]
                                                }),
                                            ]),

                                        ])
                                    ]),

                                ]),
                            ])
                        ]),

                        //市長
                        m(".col-12.d-flex.text-center.py-2", [
                            m("span", { style: { fontSize: "2rem", color: "#FFF" } }, "總統")
                        ]),
                        m(".col-12.d-flex", {
                            style: {
                                overflow: (window.isMobile) ? "scroll" : ""
                            }
                        }, [
                            m(".row.w-100.no-gutters", [
                                m(".col-12", [
                                    m("table.table.table-hover.table-bordered.mb-0", [
                                        m("thead", [
                                            m("tr", {
                                                style: {
                                                    backgroundColor: "#F2E7F7",
                                                    fontSize: "1rem"
                                                }
                                            }, [
                                                m("th[scope='col'][width='3%'].p-1.text-center", "里別"),
                                                this.select.map((_item) => {
                                                    return [
                                                        m("th[scope='col'].p-1.text-center", _item.substr(0, 2)),
                                                    ]
                                                }),
                                            ])
                                        ]),
                                        m("tbody", [
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                        backgroundColor: "rgba(255,255,255,0.4)"
                                                    }
                                                }, "柯文哲"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count = this._datas_big[_data].reduce((accumulator, _item, index) => {
                                                        return accumulator + parseInt(_item["voter1"])
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                backgroundColor: "rgba(255,255,255,0.4)",
                                                            }
                                                        }, _count),

                                                    ]
                                                }),
                                            ]),
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                        backgroundColor: "rgba(82,199,87,0.4)"
                                                    }
                                                }, "賴清德"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count = this._datas_big[_data].reduce((accumulator, _item, index) => {
                                                        return accumulator + parseInt(_item["voter2"])
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                backgroundColor: "rgba(82,199,87,0.4)",
                                                            }
                                                        }, _count),

                                                    ]
                                                }),
                                            ]),
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                        backgroundColor: "rgba(85,133,213,0.4)"
                                                    }
                                                }, "侯友宜"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count = this._datas_big[_data].reduce((accumulator, _item, index) => {
                                                        return accumulator + parseInt(_item["voter3"])
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                backgroundColor: "rgba(85,133,213,0.4)",
                                                            }
                                                        }, _count),

                                                    ]
                                                }),
                                            ]),
                                            m("tr", {
                                                style: {
                                                    color: "#FFFFFF"
                                                }
                                            }, [
                                                m("td.text-center.py-2.px-0", {
                                                    style: {
                                                    }
                                                }, "差"),
                                                this.select.map((_data, _key_index) => {
                                                    const _count_green = this._datas_big[_data].reduce((accumulator, _item, index) => {
                                                        return accumulator + parseInt(_item["voter2"])
                                                    }, 0)
                                                    const _count_blue = this._datas_big[_data].reduce((accumulator, _item, index) => {
                                                        return accumulator + parseInt(_item["voter3"])
                                                    }, 0)
                                                    return [
                                                        m("td.py-2.px-0.text-center", {
                                                            style: {
                                                                color: ((_count_green - _count_blue) > 0) ? "#00FF49" : "#FF0000"
                                                            }
                                                        }, _count_green - _count_blue),

                                                    ]
                                                }),
                                            ]),

                                        ])
                                    ]),

                                ]),
                            ])
                        ]),
                    ] : [],
                    (this.select_type == "全區分析") ? [
                        m(".col-12.d-flex", [
                            m(".row.w-100.no-gutters", [
                                m(".col-md-4.col-12.py-3.px-1", [
                                    m(".row.w-100.no-gutters", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                        }
                                    }, [
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "候選人")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "總得票數")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "土城區")
                                        ]),
                                        // m(".col-2.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                        //     m("span.text-white", "樹林區")
                                        // ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "三峽區")
                                        ]),
                                        // m(".col-2.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                        //     m("span.text-white", "鶯歌區")
                                        // ]),
                                    ]),

                                    this.voter_total.map((_voter) => {
                                        return [
                                            m(".row.w-100.no-gutters", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "1.25rem",
                                                    backgroundColor: (this.blue().includes(_voter.id)) ? "rgba(85,133,213,0.4)" : ((this.green().includes(_voter.id) ? "rgba(108,229,91,0.4)" : ""))
                                                }
                                            }, [
                                                m(".col.border.text-center", [
                                                    m("span.text-white", _voter.number)
                                                ]),
                                                m(".col.border.text-center", [
                                                    m("span.text-white", (_voter.total).toLocaleString())
                                                ]),
                                                m(".col.border.text-center", [
                                                    m("span.text-white", (_voter.tu_total).toLocaleString())
                                                ]),
                                                // m(".col-2.border.text-center", [
                                                //     m("span.text-white", (_voter.su_total).toLocaleString())
                                                // ]),
                                                m(".col.border.text-center", [
                                                    m("span.text-white", (_voter.xi_total).toLocaleString())
                                                ]),
                                                // m(".col-2.border.text-center", [
                                                //     m("span.text-white", (_voter.yi_total).toLocaleString())
                                                // ]),
                                            ])
                                        ]
                                    }),

                                    m(".row.w-100.no-gutters.pt-5", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                        }
                                    }, [
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "行政區")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "選舉人")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "投票數")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "有效票")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "無效數")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "投票率")
                                        ]),
                                    ]),
                                    m(".row.w-100.no-gutters", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                        }
                                    }, [
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "總計")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "290,715")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "210,290")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "204,511")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "5,779")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "72.34%")
                                        ]),
                                    ]),
                                    m(".row.w-100.no-gutters", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                        }
                                    }, [
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "土城區")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "198,581")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "144,687")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "140,739")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "3,948")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "72.86%")
                                        ]),
                                    ]),
                                    m(".row.w-100.no-gutters", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                        }
                                    }, [
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "三峽區")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "92,134")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "65,603")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "63,772")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "1,831")
                                        ]),
                                        m(".col.border.text-center", { style: {  } }, [
                                            m("span.text-white", "71.2%")
                                        ]),
                                    ]),

                                ]),
                                m(".col-md-4.col-12.py-3.px-1", [
                                    m(".row.w-100.no-gutters", [
                                        m(".col-12", [
                                            m(".row.w-100.no-gutters", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                                }
                                            }, [
                                                ['/', '全區', "土城", "三峽"].map((_item) => {
                                                    return [
                                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                                            m("span.text-white", _item)
                                                        ]),
                                                    ]
                                                }),
                                            ]),

                                            m(".row.w-100.no-gutters", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "1.25rem",
                                                }
                                            }, [
                                                m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                                    m("span.text-white", "民進黨")
                                                ]),
                                                this.bar_voters.map((_voter) => {
                                                    return [
                                                        m(".col.border.text-center", [
                                                            m("span.text-white", (_voter.民進黨).toLocaleString())
                                                        ]),
                                                    ]
                                                }),
                                            ]),

                                            m(".row.w-100.no-gutters", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "1.25rem",
                                                }
                                            }, [
                                                m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                                    m("span.text-white", "國民黨")
                                                ]),
                                                this.bar_voters.map((_voter) => {
                                                    return [
                                                        m(".col.border.text-center", [
                                                            m("span.text-white", (_voter.國民黨).toLocaleString())
                                                        ]),
                                                    ]
                                                }),
                                            ]),
                                            m(".row.w-100.no-gutters", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "1.25rem",
                                                }
                                            }, [
                                                m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                                    m("span.text-white", "差")
                                                ]),
                                                this.bar_voters.map((_voter) => {
                                                    return [
                                                        m(".col.border.text-center", [
                                                            m("span.font-weight-bold", {
                                                                style: {
                                                                    color: ((_voter.民進黨 - _voter.國民黨) > 0) ? "#00FF49" : "#FF0000"
                                                                }
                                                            }, (_voter.民進黨 - _voter.國民黨).toLocaleString())
                                                        ]),
                                                    ]
                                                }),
                                            ]),
                                        ]),
                                        m(".col-12.py-2", [
                                            m(BarComponent, {
                                                datas: this.voter_total,
                                                blue: this.blue,
                                                green: this.green,
                                                white: stream(null),
                                            }),
                                        ]),
                                    ])
                                ]),
                                m(".col-md-4.col-12.py-3.px-1", [
                                    m(".row.w-100.no-gutters", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                                        }
                                    }, [
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "候選人")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "總得票數")
                                        ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "土城區")
                                        ]),
                                        // m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                        //     m("span.text-white", "樹林區")
                                        // ]),
                                        m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                            m("span.text-white", "三峽區")
                                        ]),
                                        // m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                        //     m("span.text-white", "鶯歌區")
                                        // ]),
                                    ]),

                                    this.voter_total_big.map((_voter) => {
                                        return [
                                            m(".row.w-100.no-gutters", {
                                                style: {
                                                    fontSize: (window.isMobile) ? "1rem" : "1.25rem",
                                                    backgroundColor: (_voter.id == 3) ? "rgba(85,133,213,0.4)" : ((_voter.id == 1) ? "rgba(255,255,255,0.4)" : "rgba(108,229,91,0.4)")
                                                }
                                            }, [
                                                m(".col.border.text-center", [
                                                    m("span.text-white", _voter.number)
                                                ]),
                                                m(".col.border.text-center", [
                                                    m("span.text-white", (_voter.total).toLocaleString())
                                                ]),
                                                m(".col.border.text-center", [
                                                    m("span.text-white", (_voter.tu_total).toLocaleString())
                                                ]),
                                                // m(".col.border.text-center", [
                                                //     m("span.text-white", (_voter.su_total).toLocaleString())
                                                // ]),
                                                m(".col.border.text-center", [
                                                    m("span.text-white", (_voter.xi_total).toLocaleString())
                                                ]),
                                                // m(".col.border.text-center", [
                                                //     m("span.text-white", (_voter.yi_total).toLocaleString())
                                                // ]),
                                            ])
                                        ]
                                    }),
                                    (this.voter_total_big.length > 0) ? [
                                        m(".row.w-100.no-gutters", {
                                            style: {
                                                fontSize: (window.isMobile) ? "1rem" : "1.25rem",
                                            }
                                        }, [
                                            m(".col.border.text-center", { style: { backgroundColor: "rgba(255,255,255,0.3)" } }, [
                                                m("span.text-white", "差")
                                            ]),
                                            m(".col.border.text-center", [
                                                m("span.font-weight-bold", {
                                                    style: {
                                                        color: ((this.voter_total_big[1].total - this.voter_total_big[2].total) > 0) ? "#00FF49" : "#FF0000"
                                                    }
                                                }, (this.voter_total_big[1].total - this.voter_total_big[2].total).toLocaleString())
                                            ]),
                                            m(".col.border.text-center", [
                                                m("span.font-weight-bold", {
                                                    style: {
                                                        color: ((this.voter_total_big[1].tu_total - this.voter_total_big[2].tu_total) > 0) ? "#00FF49" : "#FF0000"
                                                    }
                                                }, (this.voter_total_big[1].tu_total - this.voter_total_big[2].tu_total).toLocaleString())
                                            ]),
                                            // m(".col.border.text-center", [
                                            //     m("span.font-weight-bold", {
                                            //         style: {
                                            //             color: ((this.voter_total_big[0].su_total - this.voter_total_big[1].su_total) > 0) ? "#00FF49" : "#FF0000"
                                            //         }
                                            //     }, (this.voter_total_big[0].su_total - this.voter_total_big[1].su_total).toLocaleString())
                                            // ]),
                                            m(".col.border.text-center", [
                                                m("span.font-weight-bold", {
                                                    style: {
                                                        color: ((this.voter_total_big[1].xi_total - this.voter_total_big[2].xi_total) > 0) ? "#00FF49" : "#FF0000"
                                                    }
                                                }, (this.voter_total_big[1].xi_total - this.voter_total_big[2].xi_total).toLocaleString())
                                            ]),
                                            // m(".col.border.text-center", [
                                            //     m("span.font-weight-bold", {
                                            //         style: {
                                            //             color: ((this.voter_total_big[0].yi_total - this.voter_total_big[1].yi_total) > 0) ? "#00FF49" : "#FF0000"
                                            //         }
                                            //     }, (this.voter_total_big[0].yi_total - this.voter_total_big[1].yi_total).toLocaleString())
                                            // ]),
                                        ]),
                                    ] : [],

                                    m(".col-12.py-2", [
                                        m(BarComponent, {
                                            datas: this.voter_total_big,
                                            blue: stream([3]),
                                            green: stream([2]),
                                            white: stream([1]),
                                            // white: this.white,
                                        }),
                                    ]),
                                ])
                            ])

                        ])
                    ] : [],
                ]),
                m(".footer.w-100", {
                    style: {
                        "white-space": "nowrap",
                    }
                },
                    [
                        m("span.float-right.text-white", {
                            style: {
                                fontSize: "0.5rem"
                            }
                        },
                            [
                                "Copyright ",
                                m.trust("&copy;"),
                                " 2024 Arron. All rights reserved.",
                            ]
                        )]
                )
            ]),

            ),
        ]

    }
}

export default AnalizationPage