import m from 'mithril'
import isMobile from 'lib/detect-mobile'
import Layout from 'app/pages/layout'
import * as Page from 'app/pages'
import {
    Account,
} from "app/models";

const root = document.body

window.isMobile = isMobile()

const managerRoutes = {
    '/': Layout(Page.DashboardPage),
    '/insert': Layout(Page.InsertPage),
    '/list': Layout(Page.ListPage),
    '/analization': Page.AnalizationPage,
    // '/account': Layout(Page.AccountPage),
    "/:404...": Page.ErrorPage,
}
const editorRoutes = {
    '/': Layout(Page.InsertPage),
    "/:404...": Page.ErrorPage,
}
const userRoutes = {
    '/': Layout(Page.DashboardPage),
    '/list': Layout(Page.ListPage),
    "/:404...": Page.ErrorPage,
}
const Routes = {
    "/:404...": Page.ErrorPage,
}

export function render() {
    // m.route(root, '/', managerRoutes)
    // Promise.all([Account.load(window.USER_ID)]).then((response) => {
    console.log(window.USER_NAME)
    if (window.USER_NAME == 'admin') {
        m.route(root, '/', managerRoutes)
    } else if (window.USER_NAME == 'insert') {
        m.route(root, '/', editorRoutes)
    }
    else {
        m.route(root, '/:404...', Routes)
    }

    // })
}