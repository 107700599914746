import m from 'mithril'
import moment from 'moment'
import stream from 'mithril/stream'
import classNames from 'classnames'
import {
    TextBox,
    Dialog
} from 'components'
import { Total, Tube } from 'app/models'


class ListPage {
    constructor() {
        this.voters = ['孟藹倫', '袁　興', '吳琪銘', '林金結']
        this.colors = ['#EB6877', '#f39800', '#89c997', '#00b7ee']
        if (window.isMobile) {
            this.counts = [""]
        }
        else {
            this.counts = ["", "", "", ""]
        }
        this._datas = []
        Tube.fetch().then((response) => {
            response.map((item) => {
                if (!this._datas.hasOwnProperty(item.category)) {
                    this._datas[item.category] = []
                }
                this._datas[item.category].push(item)
            })
        })
        
    }

    oncreate(vnode) {
        // this.refreshId = setInterval(function () {
        //     this._datas = []
        //     Tube.fetch().then((response) => {
        //         response.map((item) => {
        //             if (!this._datas.hasOwnProperty(item.category)) {
        //                 this._datas[item.category] = []
        //             }
        //             this._datas[item.category].push(item)
        //         })
        //     })
        // }.bind(this), 8000);
        // this.refreshTime = setInterval(function () {
        //     m.route.set('/')
        // }.bind(this), 30000);
    }
    onremove(vnode) {
        // clearInterval(this.refreshId)
        // clearInterval(this.refreshTime)
    }

    view() {
        return [
            // m(".position-fixed", {
            //     style: {
            //         bottom: "15px",
            //         left: "30px",
            //         zIndex: "2024"
            //     }
            // }, [
            //     m("button.btn.btn-outline-success.px-4", {
            //         style: {
            //             borderRadius: "25px",
            //             fontSize: "1.5rem"
            //         },
            //         class: classNames({
            //             'active': this.refreshTime == null,
            //         }),
            //         onclick: (e) => {
            //             e.preventDefault()
            //             if (this.refreshTime == null) {
            //                 this.refreshTime = setInterval(function () {
            //                     m.route.set('/')
            //                 }.bind(this), 30000);
            //             } else {
            //                 clearInterval(this.refreshTime)
            //                 this.refreshTime = null
            //             }

            //         }
            //     }, (this.refreshTime == null) ? "靜" : "動")
            // ]),
            // m(".position-fixed", {
            //     style: {
            //         bottom: "15px",
            //         right: "30px",
            //         zIndex:"2024"
            //     }
            // }, [
            //     m("button.btn.btn-outline-warning.px-4", {
            //         style: {
            //             borderRadius: "25px",
            //             fontSize: "1.5rem"
            //         },
            //         onclick: (e) => {
            //             e.preventDefault()
            //             m.route.set('/')
            //         }
            //     }, "前往總表")
            // ]),
            m(".row.no-gutters", [
                m(".col-12.py-2.d-flex.justify-content-end.align-items-center", [
                    // m(".badge.badge-light", {
                    //     style: {
                    //         fontSize: (window.isMobile) ? "1rem" : "1.25rem"
                    //     }
                    // }, "交叉分析"),
                    m(".d-flex", [
                        m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/analization")
                            }
                        },
                            m("", "前往分析")
                        ),
                        m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/")
                            }
                        },
                            m("", "返回總表")
                        ),
                    ])
                ]),
                m(".col-md-12.pr-md-0.col-12", [

                    m(".row.no-gutters", [

                        this.counts.map((count) => {
                            return [
                                m(".col-md-3.px-3.pt-3.pt-md-0", {
                                    class: classNames({
                                        "position-fixed": window.isMobile

                                    }),
                                    style:{
                                        zIndex:"2099"
                                    }
                                }, [
                                        m(".row.no-gutters.bg-white", [
                                            m(".col-4.border.text-center",
                                                m("span.font-weight-bloder.text-dark", {
                                                    style: {
                                                        fontSize: (window.isMobile) ? "1.1rem" : "1.3rem"
                                                    }
                                                }, "里別(未開數)"),
                                            ),
                                            this.voters.map((voter) => {
                                                return [
                                                    m(".col.border.text-center",
                                                        m("span.font-weight-bloder.text-dark", {
                                                            style: {
                                                                fontSize: (window.isMobile)?"1.1rem":"1.3rem"
                                                            }
                                                        }, voter),
                                                    ),
                                                ]
                                            })
                                        ])
                                    ]),
                            ]

                        }),
                        (window.isMobile)?[
                            m(".col-12",{
                                style:{
                                    minHeight:"40px"
                                }
                            })
                        ]:"",
                        Object.keys(this._datas).map((_data, index) => {
                            let voter1 = 0
                            let voter2 = 0
                            let voter3 = 0
                            let voter4 = 0
                            let opened = 0
                            this._datas[_data].map((item) => {
                                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0){
                                    opened++;
                                }
                                voter1 = voter1 + item.voter1
                                voter2 = voter2 + item.voter2
                                voter3 = voter3 + item.voter3
                                voter4 = voter4 + item.voter4
                            })
                            let _max_index = [voter1, voter2, voter3, voter4].indexOf(Math.max(voter1, voter2, voter3, voter4))
                            // console.log("opened", opened, "this._datas[_data].length", this._datas[_data].length)
                            if (_data == "三峽里"){
                                return [m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"),
                            m(".col-12.py-1",{style:{
                                backgroundColor:"rgba(255,255,255,255)"
                            }}),
                            m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"), m(".col-md-3.px-3.py-1"),
                                    m(".col-md-3.px-3.pt-1", [
                                        m(".row.no-gutters", {
                                            style: {
                                                backgroundColor: (opened == this._datas[_data].length) ?
                                                    this.colors[_max_index] : ""
                                            }
                                        }, [
                                                m(".col-4.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.55rem"
                                                        }
                                                    }, _data + " (" + (this._datas[_data].length - opened)+")"),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.4rem"
                                                        }
                                                    }, voter1),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.4rem"
                                                        }
                                                    }, voter2),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.4rem"
                                                        }
                                                    }, voter3),
                                                ),
                                                m(".col.border.text-center",
                                                    m("span.font-weight-bloder.text-white", {
                                                        style: {
                                                            fontSize: "1.4rem"
                                                        }
                                                    }, voter4),
                                                ),
                                            ])
                                    ])
                            ];
                            }
                            return m(".col-md-3.px-3.pt-1", [
                                m(".row.no-gutters", {
                                    style: {
                                        backgroundColor: (opened == this._datas[_data].length) ?
                                            this.colors[_max_index] : ""
                                    }
                                }, [
                                        m(".col-4.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.55rem"
                                                }
                                            }, _data + " (" + (this._datas[_data].length - opened) + ")"),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.4rem"
                                                }
                                            }, voter1),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.4rem"
                                                }
                                            }, voter2),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.4rem"
                                                }
                                            }, voter3),
                                        ),
                                        m(".col.border.text-center",
                                            m("span.font-weight-bloder.text-white", {
                                                style: {
                                                    fontSize: "1.4rem"
                                                }
                                            }, voter4),
                                        ),
                                    ])
                            ])
                        })
                    ])
                ]),
                m(".col-12.d-md-none", {
                    style: {
                        minHeight: "200px"
                    }
                })

            ]),

        ]
    }
}

export default ListPage