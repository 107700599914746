import m from 'mithril'
import moment from 'moment'
import GaugeComponent from './_gauge'
import RowLineComponent from './_rowLine'
import RowLineOfPricGauge from './_pricentLine'
import stream from 'mithril/stream'
import {
    TextBox,
    Dialog
} from 'components'
import { Total, Tube, Outside } from 'app/models'
import anime from 'animejs/lib/anime.es.js';
import classNames from 'classnames'



class DashboardPage {
    constructor() {
        this.voters = ['孟藹倫', '袁　興', '吳琪銘', '林金結']
        this.colors = ['#EB6877', '#f39800', '#89c997', '#00b7ee']
        this._search = ""
        this.refreshId = null
        this.tu_total_wu = 0
        this.tu_total_lin = 0
        this.xi_total_wu = 0
        this.xi_total_lin = 0
        this.total = stream(0)
        this.voter_total = []
        this._datas = []

        Total.fetch().then((response) => {
            this.voter_total = response
            this.voter_total.map((item) => {
                this.total(this.total() + item.total)
            })
            if (this.total() == 0) {
                let temp = this.voter_total[0]
                this.voter_total[0] = this.voter_total[2]
                this.voter_total[2] = temp

                temp = this.voter_total[1]
                this.voter_total[1] = this.voter_total[2]
                this.voter_total[2] = temp
            }
        })
        this.tu_tubes = 0
        this.xi_tubes = 0
        Tube.fetch().then((response) => {
            response.map((item) => {
                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0) {
                    if (parseInt(item.serial) < 2083) {
                        this.tu_tubes += 1
                        this.tu_total_wu += item.voter3
                        this.tu_total_lin += item.voter4
                    }
                    else {
                        this.xi_tubes += 1
                        this.xi_total_wu += item.voter3
                        this.xi_total_lin += item.voter4
                    }
                }
                if (!this._datas.hasOwnProperty(item.category)) {
                    this._datas[item.category] = []
                }
                this._datas[item.category].push(item)
            })
        })
        this.records = []
        Tube.fetch_record().then((response) => {
            this.records = response
        })

        this.total_type = true
    }

    oncreate(vnode) {
        // this.refreshId = setInterval(function () {
        //     // this.refreshId = null
        //     // this.voter_total = []
        //     this.total(0)
        //     this.tu_total_wu = 0
        //     this.tu_total_lin = 0
        //     this.xi_total_wu = 0
        //     this.xi_total_lin = 0
        //     this._datas = []
        //     Total.fetch().then((response) => {
        //         this.voter_total = response
        //         this.voter_total.map((item) => {
        //             this.total(this.total() + item.total)
        //         })
        //         if (this.total() == 0) {
        //             let temp = this.voter_total[0]
        //             this.voter_total[0] = this.voter_total[2]
        //             this.voter_total[2] = temp

        //             temp = this.voter_total[1]
        //             this.voter_total[1] = this.voter_total[2]
        //             this.voter_total[2] = temp
        //         }
        //     })
        //     Tube.fetch().then((response) => {
        //         this.tu_tubes = 0
        //         this.xi_tubes = 0
        //         response.map((item) => {
        //             if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0) {
        //                 if (parseInt(item.serial) < 2083) {
        //                     this.tu_tubes += 1
        //                     this.tu_total_wu += item.voter3
        //                     this.tu_total_lin += item.voter4
        //                 }
        //                 else {
        //                     this.xi_tubes += 1
        //                     this.xi_total_wu += item.voter3
        //                     this.xi_total_lin += item.voter4
        //                 }
        //             }
        //             if (!this._datas.hasOwnProperty(item.category)) {
        //                 this._datas[item.category] = []
        //             }
        //             this._datas[item.category].push(item)
        //         })
        //     })

        //     Tube.fetch_record().then((response) => {
        //         this.records = response
        //     })


        // }.bind(this), 8000);

        // this.refreshTime = setInterval(function () {
        //     m.route.set('list')
        // }.bind(this), 30000);
    }
    onremove(vnode) {
        // clearInterval(this.refreshId)
        // clearInterval(this.refreshTime)
    }

    view() {
        return [
            // m(".position-fixed", {
            //     style: {
            //         top: "35px",
            //         right: "30px",
            //         zIndex: "2024"
            //     }
            // }, [
            //     m("button.btn.btn-outline-success.px-4", {
            //         style: {
            //             borderRadius: "25px",
            //             fontSize: "1.5rem"
            //         },
            //         class: classNames({
            //             'active': this.refreshTime == null,
            //         }),
            //         onclick: (e) => {
            //             e.preventDefault()
            //             if (this.refreshTime == null) {
            //                 this.refreshTime = setInterval(function () {
            //                     m.route.set('list')
            //                 }.bind(this), 30000);
            //             } else {
            //                 clearInterval(this.refreshTime)
            //                 this.refreshTime = null
            //             }

            //         }
            //     }, (this.refreshTime == null) ? "靜" : "動")
            // ]),
            // m(".position-fixed", {
            //     style: {
            //         bottom: "35px",
            //         left: "30px",
            //         zIndex: "2024"
            //     }
            // }, [
            //     m("button.btn.btn-outline-warning.px-4", {
            //         style: {
            //             borderRadius: "25px",
            //             fontSize: "1.5rem"
            //         },
            //         onclick: (e) => {
            //             e.preventDefault()
            //             m.route.set('list')
            //         }
            //     }, "前往分析")
            // ]),
            // m(".position-fixed", {
            //     style: {
            //         bottom: "35px",
            //         right: "30px",
            //         zIndex: "2024"
            //     }
            // }, [
            //     m("button.btn.btn-outline-warning.px-4", {
            //         style: {
            //             borderRadius: "25px",
            //             fontSize: "1.5rem"
            //         },
            //         onclick: (e) => {
            //             e.preventDefault()
            //             m.route.set('list')
            //         }
            //     }, "前往里別")
            // ]),
            m(".row.no-gutters.pt-3.pt-md-0.px-md-0.px-2", [
                m(".col-12.py-2.d-flex.justify-content-end.align-items-center", [
                    m(".d-flex", [
                        m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/analization")
                            }
                        },
                            m("", "前往分析")
                        ),
                        m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/list")
                            }
                        },
                            m("", "返回里別")
                        ),
                    ])
                ]),
                m(".col-md-5.pr-md-4.col-12.py-3.py-md-0", [
                    m(".row", [
                        m(".col-12", [
                            m(".card.text-white.pl-md-5.pr-md-2.px-1.py-4", {
                                style: {
                                    background: "rgba(0,0,0,0.5)",
                                    borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                }
                            }, [
                                m('.row', [
                                    m('.col-md-12.d-flex.flex-column.justify-content-between', [
                                        m(".card-body.p-0", [
                                            m("span.card-title.d-inline-block.pb-1", {
                                                style: {
                                                    borderBottom: "10px solid #009e96",
                                                    fontSize: "1.75rem"
                                                }
                                            }, "全區開票比例 - 與中選會結果一致"),
                                        ]),
                                        m(RowLineComponent, {
                                            datas: this.voter_total,
                                            total: this.total
                                        })
                                    ]),
                                ])

                            ])
                        ]),

                    ])

                ]),
                m(".col-md-7.pr-md-4.col-12", [
                    m(".row", [
                        m(".col-12", [
                            m(".card.text-white.h-100.px-md-5.py-3.px-1", {
                                style: {
                                    background: "rgba(0,0,0,0.5)",
                                    borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                }
                            }, [
                                m('.row', [
                                    m('.col-md-12.d-flex.flex-column.justify-content-between', [
                                        m(".card-body.p-0", [
                                            // m(".d-flex.justify-content-between", [
                                            //     m("span.card-title.d-inline-block.pb-1", {
                                            //         style: {
                                            //             borderBottom: "10px solid #ffff00",
                                            //             fontSize: "1.75rem"
                                            //         }
                                            //     }, "票筒比例"),
                                            //     // m("", [
                                            //     //     m("span.card-title.d-inline-block.pb-1", {
                                            //     //         style: {
                                            //     //             fontSize: "1rem",
                                            //     //             color: "#e3e3e3"
                                            //     //         }
                                            //     //     }, "上次更新時間 - "),
                                            //     //     m("span.card-title.d-inline-block.pb-1.pl-2", {
                                            //     //         style: {
                                            //     //             fontSize: "1.25rem",
                                            //     //             color: "#e3e3e3"
                                            //     //         }
                                            //     //     }, moment().format('HH:mm:ss')),
                                            //     // ])
                                            // ]),
                                            m(".row.d-flex.justify-content-end.pt-4", [
                                                m(".col-md.col-6.text-center.order-0", [
                                                    m("span.card-title.d-inline-block.pb-1", {
                                                        style: {
                                                            borderBottom: "10px solid #ffff00",
                                                            fontSize: "1.75rem"
                                                        }
                                                    }, "票筒比例"),
                                                ]),
                                                m(".col-md.col-6.text-center.order-0", [
                                                    m(".text-left.d-inline-block", [
                                                        m("span.d-block.font-weight-light", {
                                                            style: {
                                                                fontSize: "1.6rem"
                                                            }
                                                        }, "總票數"),
                                                        m("span.font-weight-bolder", {
                                                            onupdate: (e) => {
                                                                anime({
                                                                    targets: e.dom,
                                                                    innerHTML: [0, this.total().toLocaleString()],
                                                                    easing: 'linear',
                                                                    round: 1
                                                                });
                                                            },
                                                            style: {
                                                                fontSize: "2rem"
                                                            }
                                                        }, this.total().toLocaleString()),
                                                    ])

                                                ]),
                                                m(".col-md.col-6.text-center.order-2.order-md-1.pt-4.pt-md-0", [
                                                    m(".text-left.d-inline-block", [
                                                        m("span.d-block.font-weight-light", {
                                                            style: {
                                                                fontSize: "1.6rem"
                                                            }
                                                        }, "土城區"),
                                                        m("span.font-weight-bolder", {
                                                            style: {
                                                                fontSize: "2rem"
                                                            }
                                                        }, `${this.tu_tubes} / `),
                                                        m("span.font-weight-bolder", {
                                                            style: {
                                                                fontSize: "1.3rem"
                                                            }
                                                        }, "149"),
                                                    ])
                                                ]),
                                                m(".col-md.col-6.text-center.order-3.order-md-2.pt-4.pt-md-0", {
                                                    style: {
                                                        borderLeft: "medium solid #5a767a",
                                                        borderRight: "medium solid #5a767a",
                                                    }
                                                }, [
                                                    m(".text-left.d-inline-block", [

                                                        m("span.d-block.font-weight-light", {
                                                            style: {
                                                                fontSize: "1.6rem"
                                                            }
                                                        }, "三峽區"),
                                                        m("span.font-weight-bolder", {
                                                            style: {
                                                                fontSize: "2rem"
                                                            }
                                                        }, `${this.xi_tubes} / `),
                                                        m("span.font-weight-bolder", {
                                                            style: {
                                                                fontSize: "1.3rem"
                                                            }
                                                        }, "74"),
                                                    ]),
                                                ]),
                                                m(".col-md.col-6.text-center.order-1.order-md-4", [
                                                    m(".text-left.d-inline-block", [
                                                        m("span.d-block.font-weight-light", {
                                                            style: {
                                                                fontSize: "1.6rem"
                                                            }
                                                        }, "已開"),
                                                        m("span.font-weight-bolder", {
                                                            onupdate: (e) => {
                                                                anime({
                                                                    targets: e.dom,
                                                                    innerHTML: [0, (((((this.xi_tubes + this.tu_tubes) / 223).toFixed(2)) * 100)).toFixed(2) + "%"],
                                                                    easing: 'linear',
                                                                    round: 100
                                                                });
                                                            },
                                                            style: {
                                                                fontSize: "2rem"
                                                            }
                                                        }, (((((this.xi_tubes + this.tu_tubes) / 223).toFixed(2)) * 100)).toFixed(2) + "%"),
                                                    ])

                                                ]),
                                            ])
                                        ]),


                                    ]),
                                ])

                            ])
                        ]),
                        m(".col-12.pt-4", [
                            m('.row.h-100', [
                                m(".col-md-6.col-12", [
                                    m(".card.text-white.h-100.px-md-3.py-4.px-1", {
                                        style: {
                                            background: "rgba(0,0,0,0.5)",
                                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                        }
                                    }, [
                                        m(".card-body.p-0.pt-3", [
                                            m("span.card-title.d-inline-block.pb-1", {
                                                style: {
                                                    borderBottom: "10px solid #E58F33",
                                                    fontSize: "1.75rem"
                                                },

                                            }, "土城區里別票筒"),
                                            m(".row.w-100.no-gutters",{
                                                style: {
                                                    border: "1px solid #dee2e6",
                                                }
                                            }, [
                                                Object.keys(this._datas).map((_data, index) => {
                                                    let voter1 = 0
                                                    let voter2 = 0
                                                    let voter3 = 0
                                                    let voter4 = 0
                                                    let opened = 0
                                                    this._datas[_data].map((item) => {
                                                        if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0) {
                                                            opened++;
                                                        }
                                                        voter1 = voter1 + item.voter1
                                                        voter2 = voter2 + item.voter2
                                                        voter3 = voter3 + item.voter3
                                                        voter4 = voter4 + item.voter4
                                                    })
                                                    let _max_index = [voter1, voter2, voter3, voter4].indexOf(Math.max(voter1, voter2, voter3, voter4))
                                                    if (this._datas[_data][0].city == "土城區") {
                                                        return m(".col-md-3.col-4.text-center", {
                                                            style: {
                                                                border: "1px solid #dee2e6",
                                                                backgroundColor: (opened == this._datas[_data].length) ?
                                                                    this.colors[_max_index] : ""
                                                            }
                                                        },
                                                            m("span.font-weight-bloder.text-white", {
                                                                style: {
                                                                    fontSize: "1.9rem",
                                                                }
                                                            }, _data.substring(0, 2)),
                                                            // }, (this._datas[_data].length - opened != 0) ? (_data + " (" + (this._datas[_data].length - opened) + ")") : _data),

                                                        );
                                                    }

                                                })
                                            ])
                                        ])
                                    ])
                                ]),
                                m(".col-md-6.pt-4.pt-md-0", [
                                    m(".row", [
                                        m(".col-12.d-none.d-md-flex", [

                                            m(".card.text-white.px-2.py-4", {
                                                style: {
                                                    background: "rgba(0,0,0,0.5)",
                                                    borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                                }
                                            }, [
                                                m(".row.px-3", [
                                                    m('.col-md-12.d-flex.flex-column.justify-content-between', [


                                                    ]),
                                                    // m(".col-12.py-2"),
                                                    m('.col-6', [
                                                        m(".card-body.p-0", [
                                                            m("span.card-title.d-inline-block.pb-1", {
                                                                style: {
                                                                    borderBottom: "10px solid #00b7ee",
                                                                    fontSize: "1.75rem"
                                                                }
                                                            }, "土城三峽比例"),
                                                        ]),
                                                    ]),
                                                    m('.col-3.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "1.6rem"
                                                            }
                                                        }, "土城")
                                                    ]),
                                                    m('.col-3.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "1.6rem"
                                                            }
                                                        }, "三峽")
                                                    ]),
                                                    m('.col-6.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "2rem"
                                                            }
                                                        }, "吳琪銘")
                                                    ]),
                                                    m('.col-3.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "1.75rem"
                                                            }
                                                        }, this.tu_total_wu.toLocaleString())
                                                    ]),
                                                    m('.col-3.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "1.75rem"
                                                            }
                                                        }, this.xi_total_wu.toLocaleString())
                                                    ]),
                                                    m('.col-6.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "2rem"
                                                            }
                                                        }, "林金結")
                                                    ]),
                                                    m('.col-3.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "1.75rem"
                                                            }
                                                        }, this.tu_total_lin.toLocaleString())
                                                    ]),
                                                    m('.col-3.border-bottom.d-flex.align-items-center.justify-content-center', [
                                                        m("span.card-title.d-inline-block.mb-0.py-1", {
                                                            style: {
                                                                fontSize: "1.75rem"
                                                            }
                                                        }, this.xi_total_lin.toLocaleString())
                                                    ]),
                                                ])
                                            ]),
                                        ]),
                                        // m(".col-12.pt-4", [
                                        //     m(".card.text-white.px-md-4.px-1.py-4", {
                                        //         style: {
                                        //             background: "rgba(0,0,0,0.5)",
                                        //             borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                        //         }
                                        //     }, [
                                        //         m(".row.px-3", [

                                        //             m(".pb-2", {

                                        //                 style: {
                                        //                     opacity: "1",
                                        //                     width: '100%',
                                        //                 }
                                        //             }, [
                                        //                 m("span.card-title", {
                                        //                     style: {
                                        //                         fontSize: "1.75rem"
                                        //                     }
                                        //                 }, "土城區")
                                        //             ]),
                                        //             m(".w-100.d-flex.flex-row.postion-relative", [
                                        //                 m(".", {

                                        //                     style: {
                                        //                         opacity: "1",
                                        //                         width: (this.total() == 0) ? '50%' : ((this.tu_total_wu / (this.tu_total_wu + this.tu_total_lin)).toFixed(4) * 100).toFixed(1) + "%",
                                        //                     }
                                        //                 }, [
                                        //                     m(".px-3.py-1", {
                                        //                         style: {
                                        //                             background: '#89c997',
                                        //                             minHeight: (window.isMobile) ? "1rem" : "2.5rem"
                                        //                         }
                                        //                     }, [
                                        //                     ])
                                        //                 ]),
                                        //                 m(".", {

                                        //                     style: {
                                        //                         opacity: "1",
                                        //                         width: (this.total() == 0) ? '50%' : ((this.tu_total_lin / (this.tu_total_wu + this.tu_total_lin)).toFixed(4) * 100).toFixed(1) + "%",
                                        //                     }
                                        //                 }, [
                                        //                     m(".py-1", {
                                        //                         style: {
                                        //                             background: '#00b7ee',
                                        //                             minHeight: (window.isMobile) ? "1rem" : "2.5rem"
                                        //                         }
                                        //                     }, [
                                        //                     ])
                                        //                 ]),
                                        //                 m("span.card-title.position-absolute", {
                                        //                     style: {
                                        //                         fontSize: "1.5rem",
                                        //                         left: "30px",
                                        //                     }
                                        //                 }, (this.total() == 0) ? '0%' : ((this.tu_total_wu / (this.tu_total_wu + this.tu_total_lin)).toFixed(4) * 100).toFixed(1) + "%"),

                                        //                 m("span.card-title.position-absolute", {
                                        //                     style: {
                                        //                         fontSize: "1.5rem",
                                        //                         right: "30px",
                                        //                     }
                                        //                 }, (this.total() == 0) ? '0%' : ((this.tu_total_lin / (this.tu_total_wu + this.tu_total_lin)).toFixed(4) * 100).toFixed(1) + "%")


                                        //             ]),
                                        //             m(".pt-3.pb-2", {

                                        //                 style: {
                                        //                     opacity: "1",
                                        //                     width: '100%',
                                        //                 }
                                        //             }, [
                                        //                 m("span.card-title", {
                                        //                     style: {
                                        //                         fontSize: "1.75rem"
                                        //                     }
                                        //                 }, "三峽區")
                                        //             ]),

                                        //             m(".w-100.d-flex.flex-row.postion-relative", [
                                        //                 m(".", {

                                        //                     style: {
                                        //                         opacity: "1",
                                        //                         width: (this.total() == 0) ? '50%' : ((this.xi_total_wu / (this.xi_total_wu + this.xi_total_lin)).toFixed(4) * 100).toFixed(1) + "%",
                                        //                     }
                                        //                 }, [
                                        //                     m(".px-3.py-1", {
                                        //                         style: {
                                        //                             background: '#89c997',
                                        //                             minHeight: (window.isMobile) ? "1rem" : "2.5rem"
                                        //                         }
                                        //                     }, [
                                        //                     ])
                                        //                 ]),
                                        //                 m(".", {

                                        //                     style: {
                                        //                         opacity: "1",
                                        //                         width: (this.total() == 0) ? '50%' : ((this.xi_total_lin / (this.xi_total_wu + this.xi_total_lin)).toFixed(4) * 100).toFixed(1) + "%",
                                        //                     }
                                        //                 }, [
                                        //                     m(".py-1", {
                                        //                         style: {
                                        //                             background: '#00b7ee',
                                        //                             minHeight: (window.isMobile) ? "1rem" : "2.5rem"
                                        //                         }
                                        //                     }, [
                                        //                     ])
                                        //                 ]),
                                        //                 m("span.card-title.position-absolute", {
                                        //                     style: {
                                        //                         fontSize: "1.5rem",
                                        //                         left: "30px",
                                        //                     }
                                        //                 }, (this.total() == 0) ? '0%' : ((this.xi_total_wu / (this.xi_total_wu + this.xi_total_lin)).toFixed(4) * 100).toFixed(1) + "%"),

                                        //                 m("span.card-title.position-absolute", {
                                        //                     style: {
                                        //                         fontSize: "1.5rem",
                                        //                         right: "30px",
                                        //                     }
                                        //                 }, (this.total() == 0) ? '0%' : ((this.xi_total_lin / (this.xi_total_wu + this.xi_total_lin)).toFixed(4) * 100).toFixed(1) + "%")
                                        //             ])


                                        //         ])
                                        //     ]),

                                        // ])
                                        m(".col-12.pt-4", [
                                            m(".card.text-white.h-100.px-md-3.py-4.px-1", {
                                                style: {
                                                    background: "rgba(0,0,0,0.5)",
                                                    borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                                }
                                            }, [
                                                m(".card-body.p-0", [
                                                    m("span.card-title.d-inline-block.pb-1", {
                                                        style: {
                                                            borderBottom: "10px solid #E58F33",
                                                            fontSize: "1.75rem"
                                                        },

                                                    }, "三峽區里別票筒"),
                                                    m(".row.w-100.no-gutters",{
                                                        style:{
                                                            border: "1px solid #dee2e6",
                                                        }
                                                    }, [
                                                        Object.keys(this._datas).map((_data, index) => {
                                                            let voter1 = 0
                                                            let voter2 = 0
                                                            let voter3 = 0
                                                            let voter4 = 0
                                                            let opened = 0
                                                            this._datas[_data].map((item) => {
                                                                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0) {
                                                                    opened++;
                                                                }
                                                                voter1 = voter1 + item.voter1
                                                                voter2 = voter2 + item.voter2
                                                                voter3 = voter3 + item.voter3
                                                                voter4 = voter4 + item.voter4
                                                            })
                                                            let _max_index = [voter1, voter2, voter3, voter4].indexOf(Math.max(voter1, voter2, voter3, voter4))
                                                            if (this._datas[_data][0].city == "三峽區") {
                                                                return m(".col-md-3.col-4.text-center", {
                                                                    style: {
                                                                        border:"1px solid #dee2e6",
                                                                        backgroundColor: (opened == this._datas[_data].length) ?
                                                                            this.colors[_max_index] : ""
                                                                    }
                                                                },
                                                                    m("span.font-weight-bloder.text-white", {
                                                                        style: {
                                                                            fontSize: "1.9rem",
                                                                        }
                                                                    }, _data.substring(0, 2)),
                                                                        // }, (this._datas[_data].length - opened != 0) ? (_data + " (" + (this._datas[_data].length - opened) + ")") : _data),

                                                                );
                                                            }
                                                        })
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ]),

                                ]),

                                // m(".col-md-6", [
                                //     m(".card.text-white.h-100.px-md-3.py-4.px-1", {
                                //         style: {
                                //             background: "rgba(0,0,0,0.5)",
                                //             borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                //         }
                                //     }, [
                                //         m(".card-body.p-0", [
                                //             m("span.card-title.d-inline-block.pb-1", {
                                //                 style: {
                                //                     borderBottom: "10px solid #00b7ee",
                                //                     fontSize: "1.75rem"
                                //                 },

                                //             }, "候選人比例"),
                                //             m(GaugeComponent, {
                                //                 datas: this.voter_total,
                                //                 total: this.total,
                                //                 color: this.voter_total.map((_item) => { return this.colors[parseInt(_item.id) - 1] })
                                //             }),
                                //         ])
                                //     ])
                                // ]),
                                // m(".col-md-6.pt-4.pt-md-0", [
                                //     m(".card.text-white.h-100.px-md-4.py-4.px-1", {
                                //         style: {
                                //             background: "rgba(0,0,0,0.5)",
                                //             borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                                //         }
                                //     }, [
                                //         m(".card-body.p-0", [
                                //             m("span.card-title.d-inline-block.pb-1", {
                                //                 style: {
                                //                     borderBottom: "10px solid #eb6877",
                                //                     fontSize: "1.75rem"
                                //                 }
                                //             }, "開票紀錄"),
                                //             m(".row.no-gutters", [
                                //                 this.records.map((record) => {
                                //                     return [
                                //                         m(".col-12.py-3.d-flex.justify-content-between", {
                                //                             style: {
                                //                                 borderBottomStyle: "solid",
                                //                                 borderWidth: "1px"
                                //                             }
                                //                         }, [
                                //                             m("span", record.serial + " - " + record.area),
                                //                             m("span", moment(record.updated_at).format("HH:mm")),

                                //                             // m("span", "已開票")

                                //                         ]),
                                //                     ]
                                //                 }),

                                //             ])
                                //         ])
                                //     ])
                                // ]),


                            ])


                        ])

                    ]),

                ]),

                m(".col-12.d-md-none", {
                    style: {
                        minHeight: "200px"
                    }
                })


            ]),

        ]
    }
}

export default DashboardPage