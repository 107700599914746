import m from 'mithril'
import stream from 'mithril/stream'
import Dialog from 'components/dialog'
import ProfileDialog from './dialogs/profile'
import PasswordDialog from './dialogs/password'
import {
    Account
} from 'app/models'

class Header {
    constructor(vnode) {
    }

    oncreate(vnode) {

    }

    view(vnode) {
        return [
            m(".row.no-gutters.d-flex.justify-content-end",
                [
                    (Account.data.type == "admin" || Account.data.type == "user")?[
                    m(".px-md-4.py-md-2.px-2.py-1", {
                        style: {
                            background: "rgba(0,0,0,0.4)",
                            borderRadius: "2rem"
                        },
                    }, [
                            m(m.route.Link, { href: "list" },
                                [
                                    m("span.text-white.font-weight-bolder",{
                                        style:{
                                            fontSize:(window.isMobile)?"1rem":"1rem"
                                        }
                                    }, "各里別詳細資料")
                                ]),
                        ]
                    ),
                    ]:"",
                    (Account.data.type == "admin" || Account.data.type == "editor") ? [
                    m(".px-md-4.py-md-2.px-2.py-1", {
                        style: {
                            background: "rgba(0,0,0,0.4)",
                            borderRadius: "2rem"
                        },
                    }, [
                            m(m.route.Link, { href: (Account.data.type == "editor")? "/":"insert" },
                                [
                                    m("span.text-white.font-weight-bolder", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1rem" : "1rem"
                                        }
                                    }, "輸入票數")
                                ]),
                        ]
                    ),
                    ]:"",
                    (Account.data.type == "admin" || Account.data.type == "user") ? [
                    m(".px-md-4.py-md-2.px-2.py-1", {
                        style: {
                            background: "rgba(0,0,0,0.4)",
                            borderRadius: "2rem"
                        },
                    }, [
                            m(m.route.Link, { href: "" }, [
                                m("span.text-white.font-weight-bolder",{
                                    style:{
                                        fontSize:(window.isMobile)?"1rem":"1rem"
                                    }
                                }, "即時開票系統 v4.0")
                            ]),
                        ]
                    )
                    ]:""
                ]
            )
        ]
    }
}

export default Header